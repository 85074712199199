import { Component, Vue } from 'nuxt-property-decorator';
import { Brand } from '~/models/Brand';
import { BrandHelper, BrandMapping, BrandMappingOptional, isBrandDefined, getFromMapping } from '~/utils/BrandHelper';

const brandHolder: BrandMapping<string> = {
  [Brand.Frontnode]: 'Quickbyte Global OÜ (Org. no. 14746140)',
  [Brand.Blockforia]: 'Bfinance EOOD Dba. Blockforia (206805699)',
  [Brand.Omnidrive]: 'Omnidrive LT UAB (Org/license no. 305951862)',
};

const brandHolderName: BrandMapping<string> = {
  [Brand.Frontnode]: 'Quickbyte Global OÜ',
  [Brand.Blockforia]: 'Bfinance EOOD Dba. Blockforia',
  [Brand.Omnidrive]: 'UAB OMNIDRIVE LT',
};

const brandHolderName2: BrandMapping<string> = {
  [Brand.Frontnode]: 'Quickbyte Global OÜ',
  [Brand.Blockforia]: 'Bfinance EOOD Dba. Blockforia',
  [Brand.Omnidrive]: 'Omnidrive LT UAB',
};

const brandHolderLicenseNumber: BrandMappingOptional<string> = {
  [Brand.Frontnode]: '(Org no. 14746140)',
  [Brand.Blockforia]: 'Operating License: BB-49 / 06.01.2023',
  [Brand.Omnidrive]: '(305951862)',
};

const phoneNumbers: BrandMappingOptional<string> = {
  [Brand.Frontnode]: '+372 56606173',
  [Brand.Blockforia]: '+359 88 934 5213',
  [Brand.Omnidrive]: '+370 675 15743',
};

const addresses: BrandMapping<string> = {
  [Brand.Frontnode]: ' Harju maakond, Tallinn, Kesklinna linnaosa, Tartu mnt 82, 10112',
  [Brand.Blockforia]: ', Sofia City 1463, Sofia Region, Sofia Municipality, District Triaditza, 15 Zdrave Str, fl.1a',
  [Brand.Omnidrive]: ', Gedimino pr 20, Vilnius - Lithuania',
};

const addressesTrimmed: BrandMapping<string> = {
  [Brand.Frontnode]: 'Harju maakond, Tallinn, Kesklinna linnaosa, Tartu mnt 82, 10112',
  [Brand.Blockforia]: 'Sofia City 1463, Sofia Region, Sofia Municipality, District Triaditza, 15 Zdrave Str, fl.1a',
  [Brand.Omnidrive]: 'Gedimino pr 20, Vilnius - Lithuania',
};

const cities: BrandMapping<string> = {
  [Brand.Frontnode]: 'Tallinn',
  [Brand.Blockforia]: 'Sofia',
  [Brand.Omnidrive]: 'Vilnius',
};

const countries: BrandMapping<string> = {
  [Brand.Frontnode]: 'Estonia',
  [Brand.Blockforia]: 'Bulgaria',
  [Brand.Omnidrive]: 'Lithuania',
};

const additionalInfo: BrandMapping<string> = {
  [Brand.Frontnode]:
    'Frontnode is regulated as a EU-based cryptocurrency exchange and supervised by the Estonian Financial Intelligence Unit (FIU). Billing descriptor: FRONTNODEXX. MCC: 6051.',
  [Brand.Blockforia]:
    'Blockforia is regulated as a EU-based cryptocurrency exchange and registered with operating permission BB-49/18.02.2022. Billing descriptor: BLOCKFORIAXX. MCC: 6051.',
  [Brand.Omnidrive]:
    'Omnidrive LT UAB is regulated as a EU-based cryptocurrency exchange and supervised under Bank of Lithuania (BoL). MCC: 6051. Billing descriptor: OMNIDRIVEXX.',
};

const transferDisclaimers: BrandMapping<string> = {
  [Brand.Frontnode]: '',
  [Brand.Blockforia]:
    ' This service is offered by Bfinance EOOD Dba. Blockforia, Operating License: BB-49 / 06.01.2023, Sofia City 1463, Sofia Region, Sofia Municipality, District Triaditza, 15Zdrave Str, fl.1. Your bank statement will read Blockforia.',
  [Brand.Omnidrive]: '',
};

const facebookPages: BrandMappingOptional<string> = {
  [Brand.Frontnode]: 'https://www.facebook.com/frontnodeexchange',
  [Brand.Blockforia]: null,
  [Brand.Omnidrive]: null,
};

const linkedinPages: BrandMappingOptional<string> = {
  [Brand.Frontnode]: 'https://www.linkedin.com/company/frontnode-com/',
  [Brand.Blockforia]: null,
  [Brand.Omnidrive]: null,
};

const internalWalletAddresses: BrandMapping<string> = {
  [Brand.Frontnode]: 'fnbtc',
  [Brand.Blockforia]: 'bfbtc',
  [Brand.Omnidrive]: 'odbtc',
};

const aboutUsMapping: BrandMapping<string> = {
  [Brand.Frontnode]: 'about-us',
  [Brand.Blockforia]: 'about-us',
  [Brand.Omnidrive]: 'about',
};

const contactUsMapping: BrandMapping<string> = {
  [Brand.Frontnode]: 'contact-us',
  [Brand.Omnidrive]: 'contact-us',
  [Brand.Blockforia]: 'contact-us',
};

const faqMapping: BrandMapping<string> = {
  [Brand.Frontnode]: 'frequently-asked-questions-faq',
  [Brand.Omnidrive]: 'frequently-asked-questions',
  [Brand.Blockforia]: 'frequently-asked-questions-faq',
};

const termsAndConditionsMapping: BrandMapping<string> = {
  [Brand.Frontnode]: 'terms-conditions',
  [Brand.Omnidrive]: 'docs/terms-and-conditions',
  [Brand.Blockforia]: 'terms-conditions',
};

const privacyPolicyMapping: BrandMapping<string> = {
  [Brand.Frontnode]: 'privacy-policy',
  [Brand.Omnidrive]: 'docs/privacy-policy',
  [Brand.Blockforia]: 'privacy-policy',
};

const cookiePolicyMapping: BrandMapping<string> = {
  [Brand.Frontnode]: 'cookie-policy',
  [Brand.Omnidrive]: 'docs/cookie-policy',
  [Brand.Blockforia]: 'cookie-policy',
};

const riskAppetiteMapping: BrandMapping<string> = {
  [Brand.Frontnode]: 'risk-appetite',
  [Brand.Omnidrive]: 'docs/risk-appetite',
  [Brand.Blockforia]: '',
};

const generalRiskDisclosureMapping: BrandMapping<string> = {
  [Brand.Frontnode]: 'general-risk-disclosure',
  [Brand.Omnidrive]: 'docs/general-risk-disclosure',
  [Brand.Blockforia]: 'general-risk-disclosure',
};

const refundCancellationMapping: BrandMapping<string> = {
  [Brand.Frontnode]: 'refund-cancellation',
  [Brand.Omnidrive]: 'docs/refund-and-cancellation-policy',
  [Brand.Blockforia]: 'refund-cancellation',
};

const amlKycPolicyMapping: BrandMapping<string> = {
  [Brand.Frontnode]: 'aml-kyc-policy',
  [Brand.Omnidrive]: 'docs/aml-and-kyc-policy',
  [Brand.Blockforia]: 'aml-kyc-policy',
};

const pricingAndFeesMapping: BrandMapping<string> = {
  [Brand.Frontnode]: 'pricing-and-fees-disclosures',
  [Brand.Omnidrive]: 'docs/pricing-and-fees-disclosure',
  [Brand.Blockforia]: 'pricing-and-fees-disclosures',
};

const manualAccountOpeningMapping: BrandMapping<string> = {
  [Brand.Frontnode]: 'manual-account-opening',
  [Brand.Omnidrive]: 'docs/manual-account-opening',
  [Brand.Blockforia]: 'manual-account-opening',
};

const throwBrandError = () => {
  throw new Error('Env variable Brand is not defined or contains unexpected value.');
};

@Component
export default class BrandMixin extends Vue {
  get brand(): string {
    if (!isBrandDefined()) {
      throwBrandError();
    }
    return (process.env.BRAND as String).toLowerCase();
  }

  pathToBrandSpecificImage(imageName: string): string {
    return `/${this.brand}/images/${imageName}`;
  }

  get brandColor(): string {
    return BrandHelper.getBrandColor();
  }

  get brandName(): string {
    if (!isBrandDefined()) {
      throwBrandError();
    }
    const [firstLetter, ...otherLetters] = process.env.BRAND as String;
    return [firstLetter.toUpperCase(), ...otherLetters].join('');
  }

  get capitalizedBrandDomain(): string {
    return `${this.brandName}.com`;
  }

  get brandDomain(): string {
    return `${this.brand}.com`;
  }

  get supportEmail(): string {
    return `support@${this.brandDomain}`;
  }

  get supportEmailHref(): string {
    return `mailto:support@${this.brandDomain}`;
  }

  get supportPhoneNumber(): string | null {
    return getFromMapping(phoneNumbers);
  }

  get supportPhoneNumberHref(): string | null {
    if (!this.supportPhoneNumber) {
      return null;
    }
    return `tel:${this.supportPhoneNumber.replace(/[() ]/gi, '')}`;
  }

  get brandHref(): string {
    return `https://${this.brandDomain}`;
  }

  get termsAndConditionsUrl(): string | null {
    return `${this.brandHref}/${getFromMapping(termsAndConditionsMapping)}`;
  }

  get privacyPolicyUrl(): string | null {
    return `${this.brandHref}/${getFromMapping(privacyPolicyMapping)}`;
  }

  get cookiePolicyUrl(): string | null {
    return `${this.brandHref}/${getFromMapping(cookiePolicyMapping)}`;
  }

  get riskAppetiteUrl(): string | null {
    return `${this.brandHref}/${getFromMapping(riskAppetiteMapping)}`;
  }

  get generalRiskDisclosureUrl(): string | null {
    return `${this.brandHref}/${getFromMapping(generalRiskDisclosureMapping)}`;
  }

  get refundCancellationUrl(): string | null {
    return `${this.brandHref}/${getFromMapping(refundCancellationMapping)}`;
  }

  get amlKycPolicyUrl(): string | null {
    return `${this.brandHref}/${getFromMapping(amlKycPolicyMapping)}`;
  }

  get pricingAndFeesDisclosuresUrl(): string | null {
    return `${this.brandHref}/${getFromMapping(pricingAndFeesMapping)}`;
  }

  get manualAccountOpeningUrl(): string | null {
    return `${this.brandHref}/${getFromMapping(manualAccountOpeningMapping)}`;
  }

  get statusBrandHref(): string {
    return `https://status.${this.brandDomain}`;
  }

  get transferDisclaimer(): string {
    return getFromMapping(transferDisclaimers);
  }

  get facebookLink(): string | null {
    return getFromMapping(facebookPages);
  }

  get linkedinLink(): string | null {
    return getFromMapping(linkedinPages);
  }

  get brandHolder(): string {
    return getFromMapping(brandHolder);
  }

  get brandHolderName(): string {
    return getFromMapping(brandHolderName);
  }

  get brandHolderName2(): string {
    return getFromMapping(brandHolderName2);
  }

  get brandHolder2() {
    return this.brandHolderName2 + ' ' + this.brandHolderLicenseNumber;
  }

  get brandHolderLicenseNumber(): string | null {
    return getFromMapping(brandHolderLicenseNumber);
  }

  get address(): string {
    return getFromMapping(addresses);
  }

  get addressTrimmed(): string {
    return getFromMapping(addressesTrimmed);
  }

  hrefFromMapping(mapping: BrandMapping<string>): string {
    return `${this.brandHref}/${getFromMapping(mapping)}`;
  }

  get aboutUsHref(): string {
    return this.hrefFromMapping(aboutUsMapping);
  }

  get faqHref(): string {
    return this.hrefFromMapping(faqMapping);
  }

  get contactUsHref(): string {
    return this.hrefFromMapping(contactUsMapping);
  }

  get internalWalletAddressPrefix(): string {
    return getFromMapping(internalWalletAddresses);
  }

  get additionalInfo(): string {
    return getFromMapping(additionalInfo);
  }

  get city(): string {
    return getFromMapping(cities);
  }

  get country(): string {
    return getFromMapping(countries);
  }
}
